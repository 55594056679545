:root {
    --customColorDefault: #1af87e;
    --customColorDefaultMedium: #41be7a;
    --customColorDefaultHover: #3ca86d;
    --customColorDefaultDark:#032713;
    --customColorDefaulPrimaryColor:#0a582d;
    --customColorDefaultLight: #b0f3ce;
    --customColorDefaultText: #098842;
    --CustomHoverWhiteText: rgb(204, 204, 204);
    --CustomTextMuted: rgb(180, 180, 180);
    --CustomTextMutedHover: rgb(158, 158, 158);
    --customDetailsColor: rgb(14, 11, 189);
    --customAddSubstitutionRowColor: #02140a;
    --customAddHolidayRowColor: rgb(215, 188, 228);
    --customAddBuildingRowColor: #f05209;

    /* Agreements colors */
    --agreementB2B: rgb(221, 188, 240);
    --agreementZL: rgb(164, 186, 235);
    --agreement1: rgb(247, 204, 204);
    --agreement12: rgb(245, 241, 187);
    --agreement34: rgb(156, 235, 235);


    /* Action-types colors */
    --actionTypeZn: rgb(255, 139, 44);
    --actionTypeZi: rgb(43, 167, 250);
    --actionTypeZ: rgb(255, 28, 225);
    --actionTypeW: rgb(252, 237, 100);

    /* Buildings statuses colors */
    --BuildingStatusBug: rgb(174, 51, 255);
    --BuildingStatusUpcoming: rgb(37, 214, 46);
    --BuildingStatusActive: rgb(255, 161, 38);
    --BuildingStatusEnded: rgb(255, 48, 48);
  }

/* --- CSS for login page --- */

.registry-succes-message{
    color: white;
}

.login-panel-frame:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url('../public/images/3d-crane-against-sunset-sky.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center;
    z-index: -1;
}

.login-panel-frame .login-box{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 15px gray;
    background-color: white;
    padding-top: 3.5em;
}

.login-panel-frame .logo{
    height: 2.5em;
    top: 0;
    left: 0;
    cursor: default;
}

.bg-success.registry-succes-message, .bg-success.remind-password-succes-message{
    color: white !important;
}

@media only screen and (max-width: 575px) {
    .login-panel-frame .login-box{
        width: 90%;        
    }

    .swal2-confirm, .swal2-cancel, .swal2-actions{
        width: 100%;
    }

    .swal2-cancel{
        margin-top: 1rem;
    }
}

/* --- END CSS for login page --- */

.homePage{
    font-size: 1.2rem;
}

.bg-primary{
    background-color: var(--customColorDefaulPrimaryColor) !important;
}

.btn.btn-primary, .btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:focus{
    color: black;
}

.homePage .btn-primary{
    background-color: var(--customColorDefaultMedium);
    border-color: var(--customColorDefaultMedium);
}

.homePage .btn-primary:hover, .homePage .btn-primary:active, .homePage .btn-primary.active{
    background-color: var(--customColorDefaultHover);
}

textarea:focus, input:focus{
    outline: none;
}

table :not(tfoot) > tr > td{
    border: rgb(161, 161, 161) solid 1px !important;
}

input{
    border-radius: 0.2em;
    border: gray solid 1px;
    padding: 0.1em 0.3em;
}

input[type="date"], select, option{
    cursor: pointer;
}

select{
    padding: 0.1em 0.3em;
}

option{
    padding: 1rem;
}

input[type="date"]{
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}
/* 
.form-check-input[type=checkbox] {
    border-radius: 0.3em;
} */

.form-check-input:checked {
    background-color: #0528c2;
    border-color: #000000;
}

.form-check-input.is-jumper-smaller{
    height: 1rem;
    min-height: 35px;
    min-width: 35px;
}

h6:has(> input.is-jumper-smaller){
    justify-content: space-around;
    font-size: 1.1rem;
}

div:has(> .coverLabelForDateInput), td:has(> .coverLabelForDateInput) {
    position: relative;
}

.coverLabelForDateInput{
    position: absolute;
    top: 0.1em;
    left: 18px;
    padding-top: 0.1em;
    /* padding-right: 20px; */
    /* width: fit-content; */
    padding-right: 0;
    width: calc(100% - 60px);
    background-color: white;
    text-align: left;
    cursor: pointer;
}

td .coverLabelForDateInput{
    position: absolute;
    top: 0.22em;
    left: 11px;
    padding-top: 0.1em;
    /* padding-right: 20px; */
    /* width: fit-content; */
    padding-right: 0;
    width: calc(100% - 40px);
    background-color: white;
    text-align: left;
    cursor: pointer;
}

.swal2-container td .coverLabelForDateInput{
    top: 0.6em;
}

.swal2-container .edit-building-form td .coverLabelForDateInput{
    top: 2em;
}

.swal2-container .edit-building-form td .coverLabelForDateInput{
    top: 2em;
}

.swal2-container .edit-building-form .swal-building-table.isJumper td .coverLabelForDateInput{
    top: 3em;
}

.add-building-row td .coverLabelForDateInput{
    position: absolute;
    top: 1.62em;
}

.add-building-row[data-is_jumper=true] td .coverLabelForDateInput{
    position: absolute;
    top: 2.62em;
}

.little-label-s{
    font-size: 0.8em;
}

.edit-building-form .table-wrapper.swal-tabble-wrapper:has(> form > .isJumper) {
    min-height: 240px;
}

table th, table td{
    padding: 2px 4px;
    min-width: 120px;
}

table.substitutions-table tr th:first-child, table.substitutions-table tr td:first-child, table.holidays-table tr th:first-child, table.holidays-table tr td:first-child{
    width: fit-content; 
    max-width: 80px;
    min-width: unset;
    color: var(--customDetailsColor);
 }

 table.buildings-table tr th:first-child, table.buildings-table tr td:first-child{
    width: 35px; 
    max-width: 35px;
    min-width: unset;
    color: var(--customDetailsColor);
    font-weight: bolder;
 }

table thead{
    position: relative
}

table thead tr:first-child{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 5;
}

table tr:not(.add-holiday-row, .swal-holidays-row, .add-building-row) td:has(input[type='date']){
    min-width: 180px;
    display: flex;
    align-items: center;
}

table tr.add-holiday-row td:has(input[type='date']){
    min-width: 180px;
}

table td select{
    width: fit-content;
}

table tr:not(.fancySearchRow) td input, table tr:not(.fancySearchRow) td select, table tr:not(.fancySearchRow) td textarea{
    height: 2.2rem;
    display: flex;
    align-items: center;
    min-height: 38px;
}

table tr:nth-child(even){
    background: #E6E6E6
}

table tr:nth-child(odd){
    background: #FFF
}

.table-wrapper{
    max-width: 100%;
    overflow-x: auto;
    min-height: 360px;
    height: fit-content;
    max-height: calc(100vh - 200px);
}

.holidays-page .table-wrapper{
    min-height: 150px;
}

.table-wrapper.swal-tabble-wrapper{
    min-height: 160px;
    max-height: calc(100vh - 200px);
}

.table-wrapper.swal-tabble-wrapper th{
    min-width: 140px;
}

table .td-action{
    color: var(--customColorDefaultMedium);
    /* display: flex; */
    /* justify-content: space-around; */
    padding-top: 8px;
    /* align-items: center; */
    height: 100%;
}

table .td-action a{
    color: var(--customColorDefaultMedium);
}

table .td-action svg{
    padding-left: 0.8em;
    padding-right: 0.4em;
}

table .td-action svg:first-child{
    padding-left: 0.4em;
}

table .td-action, table .th-action{
    width: 140px;
}

table .td-action svg:hover, table .td-action svg:active{
    color: var(--customColorDefaultHover);
    cursor: pointer;
}

.swal-popup-with-table{
    width: 98%;
}

.add-substitution-row{
    background-color: var(--customAddSubstitutionRowColor) !important;
    border: #1bf815 solid 3px;
}

.add-substitution-row td:nth-child(1){
    color: var(--customColorDefault) !important;
}

.add-holiday-row{
    background-color: var(--customAddHolidayRowColor) !important;
    border: #f532eb solid 3px;
}

table tr.add-building-row td:has(input[type='date']){
    min-width: 180px;
}

.add-building-row{
    background-color: var(--customAddBuildingRowColor) !important;
    border: #000000 solid 3px;
    /* min-width: 180px;
    display: flex;
    align-items: center; */
}

.fancySearchRow input{
    border: var(--customDetailsColor) solid 2px;
}

input[aria-label="Search column"]{
    background-color: rgb(219, 240, 241);
    color: red;
}

table td .react-select-custom > div:not([class$="-menu"]) input{
    border: none;
}

.substitutions-table .fancySearchRow th, .holidays-table .fancySearchRow th{
    padding-top: 1.4rem !important;
}

.to-history-btn{
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-select-custom{
    width: 220px;
}

.min-w-220, th.min-w-220{
    min-width: 220px;
}

.react-select-custom div[role="listbox"]{
    top: 0;
    width: 220px;
}

.react-select-custom div:has([role="listbox"]){
    width: 220px;
}

table td .react-select-custom > div:not([class$="-menu"]){
    border-radius: 0.2em;
    border: gray solid 1px;
    /* border: #ff50d9 solid 2px; */
    padding: 0.1em 0;
    height: 38px;
}

table td .react-select-custom > div:not([class$="-menu"]) div[class$='-IndicatorsContainer'] > div[class$='indicatorContainer']{
    padding: 2px;
}

table td .react-select-custom > div[class$="-menu"], table td .react-select-custom > div[class$="-menu"] > div, table td .react-select-custom > div[class$="-menu"] div[class$="-Group"]{
    margin: 0;
    padding: 0;
}

table td .react-select-custom > div[class$="-menu"] div[class$="-Group"] > div[class$="-group"]{
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
}

table td .react-select-custom > div[class$="-menu"], table td .react-select-custom > div[class$="-menu"] > div{
    height: fit-content;
    max-height: 240px;
}

table td .react-select-custom > div[class$="-menu"] > div > div > div >div{
    padding: 3px 10px;
    /* font-size: 0.9rem; */
}

table td .react-select-custom > div[class$="-menu"] div[class$="-Group"]:nth-child(1) > div:nth-child(1){
    background-color: #376b4b;
    /* font-size: 0.75em; */
}

table td .react-select-custom > div[class$="-menu"] div[class$="-Group"]:nth-child(1) > div:nth-child(2){
    background-color: #e1f3e8;
}

table td .react-select-custom > div[class$="-menu"] div[class$="-Group"]:nth-child(2) > div:nth-child(1){
    background-color: #53329b;
}

table td .react-select-custom > div[class$="-menu"] div[class$="-Group"]:nth-child(2) > div:nth-child(2){
    background-color: #e8e1f5;
}

table td .react-select-custom > div:not([class$="-menu"]) > div > div{
    height: calc(2.2rem - 8px);
    min-height: none;
    display: flex;
    align-items: center;
}

a.active:not(.dropdown-toggle){
    cursor: default;
}

button.navbar-toggler{
    background-color: var(--customColorDefaulPrimaryColor);
}

button.navbar-toggler .navbar-toggler-icon{
    filter: invert(1);
}

.custom-accordion-class{
    width: calc(100vw - 3em);
    max-width: 100%;
}

.customTextColor{
    color: var(--customColorDefaultText);
}

.customTextColor:not(.noHover):hover{
    color: var(--customColorDefaultDark);
}

.noHover{
    cursor: default !important;
}

.customTextlight{
    color: rgb(138, 138, 138);
}

.customTextlight:hover{
    color: rgb(94, 94, 94);
    cursor: pointer;
}

.login-panel-frame .logo .customTextColor:hover{
    color: var(--customColorDefaultText);
}

.bg-secondary-light{
    background-color: rgb(206, 206, 206);
}

.new-line {
    white-space: pre-wrap;
}

input.invalid, select.invalid, textarea.invalid{
    border: red solid 4px;
}

.add-building-row input.invalid, .add-building-row select.invalid, .add-building-row textarea.invalid{
    border: rgb(143, 36, 243) solid 4px;
}

.container-xxl{
    max-width: 2000px !important;
}

nav.navbar{
    background-color: var(--customColorDefaultDark);
    padding: 0;
    width: 100%;
}

nav.navbar{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 20;
}

.homePage{
    max-height: 100vh;
}

.content-container{
    max-width: 100vw;
    width: fit-content;
    /* max-height: calc(100vh - 10rem);     */
    display: block;
    overflow: auto;
}

nav.navbar .nav-item{
    align-items: center;
    display: flex;
}

nav.navbar .nav-item .nav-link{
    color: white;
}

nav .dropdown-menu[data-bs-popper]{
    margin-top: -8%;
}

nav .dropdown-menu, nav .dropdown-menu li a, nav .dropdown-menu li a:focus{
    background-color: var(--customColorDefaultDark);
    color: white;
}

nav.navbar .nav-item .nav-link:hover, nav.navbar .nav-item .nav-link:active, nav .dropdown-menu li a:hover, nav .dropdown-menu li a:active, nav.navbar .nav-item .nav-link.active{
    color: var(--CustomHoverWhiteText);
}

nav .dropdown-menu li a:hover, nav .dropdown-menu li a:active{
    background-color: var(--customColorDefault);
    color: black;
}

nav.navbar .btn{
    color: white;
    font-size: 1.7rem;
}

nav.navbar .btn:hover{
    color: var(--CustomHoverWhiteText);
}

.customBgColor{
    background-color: var(--customColorDefault);
}

.username-container{
    font-size: smaller;
    width: 100vw;
    text-align: right;
    padding-right: 1.5rem;
}

.username-container .svg-inline--fa {
    height: 0.8em;
}

nav .navbar-brand, nav .navbar-brand:hover, nav .navbar-brand:active, nav .navbar-brand:focus{
    color: var(--customColorDefault);
    font-weight: 500;
    cursor: pointer;
}

nav .navbar-toggler, nav .navbar-toggler:focus{
    font-size: smaller;
    border: white 2px solid;
    box-shadow: none;
}

nav .navbar-toggler:hover, nav .navbar-toggler:active{
    border: var(--CustomHoverWhiteText) 2px solid;
    box-shadow: none;
}

nav .navbar-toggler span{
    color: white;
}

.h-100vh{
    height: 100vh;
}

.logoutBtnContainer{
    padding-right: 1rem;
}

.logoutBtnContainer span{
    font-size: smaller;
}

.agreement-B2B{
    background-color: var(--agreementB2B);
}

.agreement-ZL{
    background-color: var(--agreementZL);
}

.agreement-1{
    background-color: var(--agreement1);
}

.agreement-12{
    background-color: var(--agreement12);
}

.agreement-34{
    background-color: var(--agreement34);
}

.action-type-zn{
    background-color: var(--actionTypeZn);
}

.action-type-zi{
    background-color: var(--actionTypeZi);
}

.action-type-z{
    background-color: var(--actionTypeZ);
}

.action-type-w{
    background-color: var(--actionTypeW);
}

.bg-white{
    background-color: white;
}

.swal-form, .swal-form form, .swal-form .row{
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
}

.no-action, .no-action a, .no-action span, .no-action p{
    cursor: default !important;
}

.min-w-120{
    min-width: 120px;
}

.info-box-swal-popup h2{
    color: red;
    font-weight: bolder;
    text-decoration: underline;
    margin-bottom: 1.5rem;
}

.info-box-swal-popup h4{
    color: red;
}

#infoBox, #holidaysInfoBox{
    cursor: pointer;
}

/* #infoBox{ */
    /* left: 120px; */
    /* margin-left: 5rem; */
    /* position: absolute; */
/* } */

.redirect-icon{
    font-size: smaller;
    cursor: pointer;
    color: var(--CustomTextMuted);
}

.redirect-icon:hover, .redirect-icon:active{
    color: var(--CustomTextMutedHover);
}

.font-smaller{
    font-size: smaller !important;
}

.font-smaller-xs{
    font-size: 0.85rem !important;
}

.font-12{
    font-size: 1.2rem !important;
}

.accordion-button.collapsed{
    background-color: #dadada;
    box-shadow:  0 0 0 0.25rem rgba(114, 114, 114, 0.25);
}

#HolidaysChartContainer, #BuildingsChartContainer{
    overflow: auto;
    flex-direction: column;
    margin-bottom: 3rem;
}

#HolidaysChartContainer[data-standard_year='true'] .month-box[data-days_count='28'], #BuildingsChartContainer[data-standard_year='true'] .month-box[data-days_count='28']{
    width: 341px;
}

#HolidaysChartContainer[data-standard_year='true'] .month-box[data-days_count='29'], #BuildingsChartContainer[data-standard_year='true'] .month-box[data-days_count='29']{
    width: 356px;
}

#HolidaysChartContainer[data-standard_year='true'] .month-box[data-days_count='30'], #BuildingsChartContainer[data-standard_year='true'] .month-box[data-days_count='30']{
    width: 365px;
}

#HolidaysChartContainer[data-standard_year='true'] .month-box[data-days_count='31'], #BuildingsChartContainer[data-standard_year='true'] .month-box[data-days_count='31']{
    width: 378px;
}

#HolidaysChartContainer[data-standard_year='true'] .month-box[data-days_count='31']:last-child, #BuildingsChartContainer[data-standard_year='true'] .month-box[data-days_count='31']:last-child{
    width: 366px;
}

#HolidaysChartLegend ul{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
}

#HolidaysChartLegend ul li{
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    margin-bottom: 5px;
    padding-right: 18px;
}

#HolidaysChartLegend ul li span{
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 3px;
    border-style: solid;
    border-width: 1px;
    /* to awoid click bug */
    line-height: 22px;
    border-radius: 3px;
}

#HolidaysChartLegend ul li.legend-box-dataset-hide span{
    opacity: 0.3;
}

#HolidaysChartLegend ul li p{
    margin: 0;
    padding: 0;
    color: rgba(102, 102, 102, 1);
}

#HolidaysChartLegend ul li.legend-box-dataset-hide p{
    text-decoration: line-through;
    color: rgb(131, 130, 130);
}

#HolidaysChartContainer .chartContainer{
    height: 300px;
}

#BuildingsChartContainer .chartContainer{
    height: 220px;
}

#HolidaysChartContainer .chartContainer, #BuildingsChartContainer .chartContainer{
    width: 100%;
    min-width: 100%;
}

#HolidaysChartContainer .chartContainer, #BuildingsChartContainer .chartContainer{
    width: 4500px;
    min-width: 4500px;
}

.chartContainer{
    position: relative;
    padding-top: 25px;
}

.chart-absolute-paint-area{
    position: absolute;
    top: 0;
    left: 32px;
    /* width: calc(100% - 63px); */
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: red; */
}


.chart-absolute-paint-area .month-box{
    border-left: solid rgb(128, 127, 127) 2px;
    border-top: solid rgb(202, 202, 202) 1px;
    width: 100%;
}

.chart-absolute-paint-area .month-box:last-child{
    border-right: solid black 2px;
}

.chart-absolute-paint-area .month-box p{
    padding-top: 2px;
    text-align: center;
}

.chart-absolute-paint-area .month-box[data-days_count='28']{
    width: 341px;
}

.chart-absolute-paint-area .month-box[data-days_count='29']{
    width: 352px;
}

.chart-absolute-paint-area .month-box[data-days_count='30']{
    width: 364px;
}

.chart-absolute-paint-area .month-box[data-days_count='31']{
    width: 377px;
}

.chart-absolute-paint-area .month-box[data-days_count='31']:last-child{
    width: 365px;
}

.holidays-year-container{
    font-size: smaller;
}

.holidays-year-container input{
    width: 100px;
    border-color: var(--customColorDefaultMedium);
    border-width: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.holidays-page > .table-wrapper{
    padding-top: 0 !important;
}

#HolidaysChartLegend  .accordion-item{
    border: none;
}

#HolidaysChartLegend .accordion-button{
    width: fit-content;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

#HolidaysChartLegend .accordion-button {
    font-size: 1.1rem;
    color: var(--customColorDefaultMedium);
}

#HolidaysChartLegend .accordion-button::after{
    filter: invert(70%);
}

#HolidaysChartLegend .accordion-button span{
    padding-right: 0.5em;
    text-decoration: underline;
}

#HolidaysChartLegend .accordion-body{
    background-color: rgb(234, 247, 253)
}

#accordionSettingsPassword .accordion-button{
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 1.1rem;
}

#accordionSettingsPassword .accordion-item{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

#accordionSettingsPassword .accordion-button span{
    padding-right: 0.5em;
}

#accordionSettingsPassword .accordion-body{
    background-color: rgb(234, 247, 253);
}

.text-smooth-pink{
    color: rgb(232, 8, 240);
    
    -webkit-transition: color 1300ms linear;
    -moz-transition: color 1300ms linear;
    -o-transition: color 1300ms linear;
    -ms-transition:color 1300ms linear;
    transition: color 1300ms linear;
}

.text-smooth-orange{
    color: rgb(245, 125, 12);
    
    -webkit-transition: color 1300ms linear;
    -moz-transition: color 1300ms linear;
    -o-transition: color 1300ms linear;
    -ms-transition: color 1300ms linear;
    transition: color 1300ms linear;
}

.files .files-dropzone{
    border: rgb(201, 201, 201) solid 2px;
    border-radius: 5px;
    padding:  0.3rem 0.7rem;
    max-width: 400px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.files-list-item-preview{
    display: flex;
    justify-content: center;
}

.files-list-item-preview-image{
    width: 150px;
    height: auto;
    padding-top: 0;
}

.files-list-item-content-item{
    max-width: 140px;
}

.files-list-item-remove{
    font-size: 2rem;
}

.files-list-item .file-name-smaller{
    font-size: 0.9em;
}

.files-list-item .file-size-text-smaller{
    font-size: 0.7em;
}

.files-list-item-remove-container{
    position: absolute;
    right: 0;
}

.bg-custom{
    background-color: var(--customColorDefaultDark) !important;
    color: white !important;
}

.border-radious-custom{
    border-radius: 5px;;
}

.employee-file{
    display: flex;
    flex-direction: column;
    width: 160px;
    align-items: center;
    padding: 5px 10px;
}

.employee-file-cover{
    width: 120px;
    height: 170px;
    box-shadow: 0px 0px 10px rgb(173, 173, 173);
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
}

.employee-file-cover img{
    width: 100%;
    height: auto;
    max-height: 100%;
}

.employee-file-upload-cover{
    width: 120px;
    height: 170px;
    box-shadow: 0px 0px 10px rgb(173, 173, 173);
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    margin-top: 10px;
}

.employee-file-upload-cover img{
    width: 100%;
    height: auto;
    max-height: 100%;
}

.employee-file .file-name{
    width: 100%;
    margin: 0;
    line-height: 1.2rem;
    padding-top: 5px;
}

.employee-file .file-name p{
    margin: 0;
}

.files-list-item-remove{
    margin-right: 14px !important;
}

.files-list-item-remove.existing-files-remove{
    margin-right: 14px !important;
    padding-top: 0;
    font-size: 1.8rem;
}

.files-list-item-download-container{
    position: absolute;
    bottom: 0;
    right: 8px;
    padding-top: 0;
    font-size: 1.9rem;
}

.files-list-item-prevew-container{
    position: absolute;
    bottom: 60px;
    right: 30px;
    padding-top: 0;
}

.files-list-item-prevew-container button, .files-list-item-prevew-container button:hover, .files-list-item-prevew-container button:active{
    font-size: 2rem;
    padding:  2px 8px;
    background-color: rgba(255, 255, 255, 0.664);
    border-radius: 10px;
}

.employee-file .file-extension-name{
    font-size: larger;
    position: relative;
    top: -30px;
}

#file-preview{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    height: auto;
    max-height: 100%;
    z-index: 5;
}

#file-preview button{
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 21;
    font-size: 2rem;
    padding: 0.05rem 0.8rem;
    background-color: rgba(255, 255, 255, 0.664);
}

#file-preview:has(iframe) button{
    /* right: unset; */
    top: 60px;;
}

#file-preview img{
    width: 100%;
    z-index: 20;
}

.hidden-little-iframe{
    width: 0;
    height: 0;
}

#file-preview:has(> iframe){
    max-width: 900px;
}

#file-preview iframe{
    width: 100%;
    z-index: 20;
    height: 600px;
    max-height: 95%;
}

.files-card{
    min-height: 600px;
}

.existing-files-container{
    background-color: rgb(236, 235, 235);
}

.employee-file-cover{
    background-color: white;
}

.checked{
    border: rgb(27, 204, 27) solid 4px !important;
}

input[type=checkbox]{
    cursor: pointer;
}

.cursor-pointer{
    cursor: pointer !important;
}

.single-employee-data table > tbody > tr > td:nth-child(3), .single-employee-data table > tbody > tr >td:nth-child(4){
    min-width: 180px;
}

.account-settings-container{
    width: 600px;
    max-width: 100%;
}

.account-settings-container li{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.account-settings-container li > div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.account-settings-container li > div >label{
    padding-right: 1rem;
}

.account-settings-container .accordion-body div{
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.account-settings-container input[type='range']{
    width: 90px;
}

input[type='range']{
    cursor: pointer;
}

.settings-danger-zone{
    background-color: rgb(233, 232, 232);
    border-radius: 10px;
    padding: 1rem 0.5rem;
}

.navLogo{
    width: 24px;
    height: auto;
    padding-right: 5px;
}

.table-td-xl-2{
    min-width: 400px;
}

.table-td-xl{
    min-width: 330px;
}

.table-td-m{
    width: 160px;
    min-width: 20px;
}

.table-td-s{
    width: 100px;
    min-width: 20px;
}

.table-td-xs{
    width: 50px;
    min-width: 20px;
}

.table-td-xxs{
    width: 60px !important;
}

table.buildings-table [data-building_status='bug']{
    background-color: var(--BuildingStatusBug);
}

table.buildings-table [data-building_status='upcoming']{
    background-color: var(--BuildingStatusUpcoming);
}

table.buildings-table [data-building_status='active']{
    background-color: var(--BuildingStatusActive);
}

table.buildings-table [data-building_status='ended']{
    background-color: var(--BuildingStatusEnded);
}


.color-text-upcoming{
    color: var(--BuildingStatusUpcoming);
}

.color-text-active{
    color: var(--BuildingStatusActive);
}

.color-text-ended{
    color: var(--BuildingStatusEnded);
}

.hide-column-btn{
    font-size: smaller;
    cursor: pointer;
    background-color: rgb(215, 107, 223) !important;
    border-color: rgb(215, 107, 223) !important;
    color:white !important;
}

.hide-column-btn:hover, .hide-column-btn:active{
    cursor: pointer;
    background-color: rgb(198, 85, 206) !important;
}

.hide-show-table-column{
    /* width: 100%; */
    /* text-align: end; */
    position: absolute;
    right: 0;
    padding: 0 1rem;
    z-index: 3;
}

.accordion-collapse .hide-show-table-column{
    display: none;
}

.accordion-collapse.show .hide-show-table-column{
    display: inherit;
}

.accordion-collapse .hide-show-table-column{
    margin-top: 2.7em;
}

.accordion-collapse .fancySearchRow th{
    padding-top: 2.2em !important;
}

.swal-hide-show-columns .listPosition {
    padding: 0.3rem 0.3rem;
}

.swal-hide-show-columns .listPosition label{
    cursor: pointer;
    width: 100%;
}

.swal-hide-show-columns .listPosition:nth-child(odd){
    background-color: rgb(248, 226, 250);
}

.swal-hide-show-columns .listPosition:nth-child(even){
    background-color: rgb(241, 205, 243);
}

.swal-hide-show-columns .listPosition:hover, .swal-hide-show-columns .listPosition:active{
    background-color: rgb(219, 171, 221);
}


/* --RESPONSIVE-- */

/* < xl */
@media only screen and (max-width: 1199px) {
    .files{
        display: flex;
        padding-bottom: 1rem;
    }
}


/* < lg */
@media only screen and (max-width: 991px) {

    nav.navbar{
        align-items: baseline;
    }

    nav.navbar > .container-fluid{
        justify-content: flex-start;
        width: fit-content;
        margin: 0;
        height: 100%;
        /* display: inline-block; */
    }

    .custom-accordion-class{
        width: calc(100vw - 1em);
    }

    .files{
        flex-direction: column;
    }

    .files .files-list{
        padding-top: 1rem;
    }

    .files .files-dropzone{
        width: 100;
        max-width: 100%;
    }

}


/* < md */
@media only screen and (max-width: 767px) {

    .logoutBtnContainer{
        padding-right: 0.5rem;
    }

    .hide-column-btn{
        background: none;
        border: none;
    }   
    
    .hide-column-btn span{
        display: none;
    }

    .hide-column-btn{
        background-color: rgba(128, 128, 128, 0) !important;
        border-color:  rgba(128, 128, 128, 0) !important;
        color:rgb(214, 19, 231) !important;
        font-size: medium;
    }

    .hide-column-btn:hover, .hide-column-btn:active{
        color:rgb(187, 0, 204);
    }

}

/* < sm */
@media only screen and (max-width: 575px) {

    .homePage{
        font-size: 16px;
    }

    nav.navbar > .container-fluid{
        justify-content: center;
        width: 100%;
    }

    nav.navbar > .container-fluid .navbar-nav{
        padding-top: 0.5rem;;
    }

    nav.navbar > .container-fluid .nav-item{
        justify-content: center;
    }

    .logoutBtnContainer{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 0.1rem;
        margin-bottom: 0.3rem;
        padding-left: 0;
    }

    nav.navbar .logoutBtnContainer button.btn{
        font-size: 1.3rem;
    }

    .content-container{
        width: 100vw;
        display: block;
        overflow: auto;
    }
    
    .custom-accordion-class{
        width: calc(100vw - 0.2em);
    }

    #file-preview{
        width: 95%;
    }

    .settings-danger-zone button{
        width: 100%;
    }

    .homePage .coverLabelForDateInput{
        top: 0.48em;
    }

    .add-building-row td .coverLabelForDateInput{
        position: absolute;
        top: 2.2em;
    }
    
    .add-building-row[data-is_jumper=true] td .coverLabelForDateInput{
        position: absolute;
        top: 3.3em;
    }
    

    .homePage .username-container{
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }

}

/* < 400px */
@media only screen and (max-width: 400px) {
    .to-history-btn{
        padding: 3px 7px;
    }

    .to-history-btn svg{
        display: none;
    }

    .btn-group.files-btn-group{
        flex-wrap: wrap;
    }

    .btn-group.files-btn-group .btn{
        width: 100%;
    }
}

/* > sm */
@media only screen and (min-width: 576px) {

    .position-sm-absolute{
        position: absolute !important;
    }

    .mb-sm-0{
        margin-bottom: 0 !important;
    }

    .w-sm-auto{
        width: auto !important;
    }
}


/* > xl */
@media only screen and (min-width: 1200px) {

    nav.navbar .navbar-collapse{
        display: flex;
        justify-content: center;
    }

    nav.navbar .navbar-collapse ul{
        margin: 0 !important;
    }

    nav.navbar .nav-item{
        padding: 0 1rem;
    }

    .lg-d-flex-justify-center{
         display: flex !important;
         justify-content: center !important;
    }

}
